@use 'shared' as *;

.mat-mdc-radio-button .mdc-radio__background {
  // hide hover effect
  &:before {
    display: none;
  }

  .mdc-radio__outer-circle {
    border-width: 1px;
  }
}

.mdc-radio {
  --mat-radio-checked-ripple-color: ${$container-secondary};
  --mdc-radio-unselected-icon-color: #{$icon-secondary-01};
  --mdc-radio-unselected-hover-icon-color: ${$icon-primary};
  --mdc-radio-selected-hover-icon-color: ${$icon-primary};
  --mdc-radio-selected-icon-color: #{$icon-primary};
  --mdc-radio-selected-pressed-icon-color: ${$icon-primary};
  --mdc-radio-selected-focus-icon-color: ${$icon-primary};
  --mdc-radio-disabled-unselected-icon-color: #{$icon-disabled};
  --mdc-radio-disabled-selected-icon-color: #{$icon-disabled};
  --mat-radio-disabled-label-color: #{$text-disabled};
  --mdc-radio-disabled-selected-icon-opacity: 1;
  --mdc-radio-disabled-unselected-icon-opacity: 1;

  &--disabled {
    .mdc-radio__background {
      .mdc-radio__outer-circle {
        background-color: $container-secondary;
      }
    }
  }
}
