@use 'shared' as *;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  // Country Flags font will be picked only for the flag emoji, country-flag-emoji-polyfill sets unicode-range to handle that;
  font-family: 'Twemoji Country Flags', 'Inter', sans-serif;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  overflow: hidden; // mat-sidenav-container is handling overflow
}

// add margin top to global panel class for mat menu;
.mat-mdc-menu-panel {
  margin-top: $spacing-8;
}

// reset mat menu content bottom padding;
.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding-bottom: 0;
}

.help-widget__menu-panel.mat-mdc-menu-panel {
  margin-bottom: $spacing-16;
  margin-top: 0;

  @include xs {
    margin-bottom: 0;
    position: absolute;
    max-width: unset;
    width: 100vw;
    bottom: -#{pxToRem(75)};
    left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.inbox-widget__menu-panel.mat-mdc-menu-panel {
  margin-bottom: $spacing-16;
  margin-top: 0;

  @include md {
    margin-bottom: 0;
    position: absolute;
    top: -#{pxToRem(52)};
    right: 0;
    border-radius: 0;
  }

  @include xs {
    width: 100vw;
  }
}

// set max height of dropdown mat-menu panel
.fdt-dropdown-panel .fdt-dropdown-menu {
  max-height: pxToRem(460);
}

// custom styles for entity-switcher dropdown mat-menu panel;
.entity-switcher-panel {
  max-width: unset !important;
}

// TODO: put this back in the library with the table style of the next gen
.fdt-table__body {
  @extend %body-2-medium;
}

// TODO: delete below when class is moved to library
.form-field--hidden-header .form-field__header {
  display: none;
}

// TODO: remove and fix this temporary solution to allow dynamic table row routerlink
a.cell-link {
  text-decoration: none; /* no underline */
  color: inherit;
  white-space: nowrap;
}

// TODO: delete below when prefix become optional in autocomplete
.autocomplete-without-prefix .fdt-input-search-field__prefix {
  display: none;
}

.fdt-copy-container {
  display: flex;
  align-items: center;
  gap: $spacing-4;
}

.text-right {
  // we need this for data-table;
  // Data-Table programatically adds this class
  text-align: right;
}

// TODO: This should be handled in common
.network-label {
  @extend %body-2-medium;
  color: $text-secondary-02;
  background: $container-secondary;
  border-radius: $border-radius-full;
  padding: 0 $spacing-6;
  font-size: $spacing-12;
}

.fdt-label--tooltip {
  display: flex;
  align-items: center;
  gap: $spacing-4;
}

.app-announcement-active {
  mat-sidenav-container {
    height: calc(100% - 52px) !important;
  }

  .full-size-modal-wrapper__body {
    height: calc(100vh - pxToRem(80 + 52)) !important;
  }
}

.fdt-btn--floating {
  box-shadow: $elevation-medium;
}

fdt-copy-to-clipboard {
  display: inline-flex;
}

.stepper {
  .mat-content.mat-content-hide-toggle {
    margin-right: 0;
  }

  .mat-expansion-panel-body {
    display: flex;
    flex-direction: column;
    padding: $spacing-32 $spacing-20;
    gap: $spacing-32;
  }
}

// Reset default styles of text area in scope of conversation class;
.conversation .fdt-textarea__input {
  border: 0;
  padding-bottom: 0;
  border-radius: 0;
}

// clickable link inside popover component;
.popover-link {
  cursor: pointer;
  text-decoration: underline;
  color: $text-primary-inverted;
}

.fdt-phone-input__flag {
  font-family: 'Twemoji Country Flags', 'Inter', sans-serif;
}

.cell-type-amount > .cell-activity-link {
  display: flex;
  flex-flow: column;
  gap: $spacing-4;
}

.hidden {
  visibility: hidden;
  position: fixed;
  height: 0;
}

.address-form {
  display: flex;
  flex-flow: column;
  gap: $spacing-32;
}

.fdt-table__cell > .fdt-whitelist__copy-container {
  display: flex;
  align-items: center;
  gap: $spacing-4;
}
