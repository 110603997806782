@use 'shared' as *;

.fdt-export-dropdown {
  @extend %button-1;
  display: inline-flex;
  gap: $spacing-6;
  cursor: pointer;

  &[aria-expanded='true'] {
    & .fdt-arrow-icon {
      transform: rotate(180deg);
    }
  }
}

.mat-mdc-menu-panel.fdt-export-dropdown-menu {
  border-radius: $border-radius-12;
  margin-bottom: pxToRem(8);

  &.mat-mdc-elevation-specific {
    box-shadow: $box-shadow-medium;
  }

  .fdt-export-dropdown-menu-item {
    @extend %body-1-medium;
    padding: $spacing-12;
    color: $text-primary;
    min-height: pxToRem(40);
    min-width: pxToRem(120);

    &:not(:last-child) {
      border-bottom: 1px solid $border-primary;
    }

    & .mat-mdc-menu-item-text {
      display: flex;
      gap: $spacing-12;
    }

    & .mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text-secondary-01;
    }
  }
}

.fdt-export-menu.mat-mdc-menu-panel {
  max-width: pxToRem(600);
  border-radius: $border-radius-12;
  border: 1px solid $color-N-300;
  box-shadow: $elevation-medium !important; // override mat-elevation

  .mat-mdc-menu-content {
    padding: 0;
  }
}
