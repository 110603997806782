$sm: 768px;
$md: 1024px;
$lg: 1280px;
$xl: 1400px;

/*
  Note: To ensure the correct functioning of these media queries,
  they should be applied in reverse order (from lg to xs). This is because
  each mixin targets progressively smaller screen sizes, and applying larger
  breakpoints first prevents smaller ones from being overridden.
*/

// Applies styles for devices smaller than $sm (< 768px)
@mixin xs {
  @media (max-width: #{$sm - 1px}) {
    @content;
  }
}

// Applies styles for devices smaller than $md (< 1024px)
@mixin sm {
  @media (max-width: #{$md - 1px}) {
    @content;
  }
}

// Applies styles for devices smaller than $lg (< 1280px)
@mixin md {
  @media (max-width: #{$lg - 1px}) {
    @content;
  }
}

// Applies styles for devices smaller than $xl (< 1400px)
@mixin lg {
  @media (max-width: #{$xl - 1px}) {
    @content;
  }
}
