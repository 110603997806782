@use 'shared' as *;

// this current style is mostly added because of filter dropdown
// I hope the same padding and gaps are in regular forms;
.form-footer-container {
  display: flex;
  justify-content: flex-end; /* Aligns items to the right */

  padding: $spacing-12 $spacing-16;
  gap: $spacing-16;
  // Our dropdowns with apply/clear controls have minimal width 280px
  min-width: pxToRem(280);
  box-sizing: border-box;
}

/*
Used computed value here.
Since values are dynamic, there is no special rules for heights,
except paddings, borders, etc.
Not overthinking it, so just used predefined heights.
 */
$funds-option-height: 44.5;
.funds-dropdown-small {
  // Relied on designs, that's why 4 items visible
  &.fdt-dropdown-panel {
    .fdt-dropdown-menu {
      max-height: pxToRem($funds-option-height * 4);
    }
  }
}

.fdt-mat-mdc-menu-panel.fdt-dropdown-panel.otc-currency-dropdown {
  margin-top: $spacing-8;
}
