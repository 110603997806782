@use 'shared' as *;

// TODO: come back later to tweak styling for radio button
.fdt-radio-button {
  &.mat-mdc-radio-button.mat-accent {
    --mdc-radio-unselected-icon-color: $icon-primary;
    --mdc-radio-selected-icon-color: $icon-primary;
    --mdc-radio-selected-hover-icon-color: $icon-primary;
    --mdc-radio-selected-focus-icon-color: $icon-primary;
    --mdc-radio-selected-pressed-icon-color: $icon-primary;
    --mdc-radio-checked-ripple-color: $icon-primary;
    --mat-radio-checked-ripple-color: $icon-primary;

    & .mdc-form-field {
      --mat-checkbox-label-text-size: 14px; // .body-2-medium
    }
  }

  .mdc-radio__outer-circle {
    border-width: pxToRem(1);
  }
}
