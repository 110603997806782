@use 'shared' as *;

.fdt-content-container {
  max-width: pxToRem(1150);
  margin: 0 auto;
}

.fdt-page-layout {
  &__header {
    @extend %subtitle-1;
    color: $text-primary;
    border-bottom: 1px solid $border-secondary;
    padding: $spacing-12 $spacing-24;

    @include md {
      border: 0;
    }

    @include sm {
      padding: $spacing-12 $spacing-16;
      border: 0;
    }
  }

  &__body {
    @extend .fdt-content-container; // want to allow using it as a class;
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
    padding: $spacing-24;

    @include sm {
      padding: $spacing-16 $spacing-16 $spacing-24;
    }
  }
}

.fdt-details-layout {
  &__header {
    @extend %body-2-medium;
    color: $text-primary;
    border-bottom: 1px solid $border-secondary;
    padding: $spacing-12 $spacing-24;

    @include md {
      padding: $spacing-12 $spacing-16;
      border: 0;
    }

    .back-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: $spacing-6;
      outline: 0;
      background: none;
      border: 0;
      width: fit-content;
      text-decoration: none;
      color: inherit;
    }
  }

  &__body {
    @extend .fdt-content-container; // want to allow using it as a class;
    display: flex;
    gap: $spacing-24;
    padding: $spacing-24;
    justify-content: center;

    @include sm {
      flex-direction: column;
      gap: $spacing-20;
      padding: $spacing-16 $spacing-16 $spacing-24;
      align-items: center;
    }

    &--column {
      flex-direction: column;
    }
  }
}
