@use 'shared' as *;

.fdt-info-card {
  @extend %body-2-medium;
  display: flex;
  gap: $spacing-12;
  border-radius: $border-radius-12;
  padding: $spacing-10 $spacing-12;
  color: $text-secondary-02;
  background-color: $container-secondary;

  &--grey-bg {
    background-color: $container-tertiary;
  }

  &__link {
    color: inherit;
  }
}
