@forward 'variables';
@forward 'extends';
@forward 'utils';

/*
Please note that in future will replace @import "shared" with @use
See first heads up section
https://sass-lang.com/documentation/at-rules/import/

instead use new syntax @use and @import
https://sass-lang.com/documentation/at-rules/use/
https://sass-lang.com/documentation/at-rules/import/

Differences
https://stackoverflow.com/questions/62757419/whats-the-difference-between-import-and-use-scss-rules

Old
@use 'shared' as *;
will be replaced with
@use 'variables' as *;
to keep backward compatibility.
*/
