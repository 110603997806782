@use 'shared' as *;

.fdt-dialog-cdk-overlay-backdrop.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-color: $overlay-dim;
  backdrop-filter: blur(pxToRem(5));
}

// no shadow
.mat-mdc-dialog-container.cdk-dialog-container {
  --mdc-dialog-container-elevation: none;

  & .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: $spacing-12;
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel:not(.fdt-dialog-cdk-overlay-panel) {
  @include xs {
    position: absolute !important;
    max-width: unset !important;
    width: 100vw !important;
    bottom: 0 !important;

    .mdc-dialog__surface {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

// no border radius for full page modal
.fdt-dialog-cdk-overlay-panel
  .mat-mdc-dialog-container.cdk-dialog-container
  .mat-mdc-dialog-surface.mdc-dialog__surface {
  border-radius: 0;
}

.app-announcement-active {
  // add margin for full page modas, to display the announcement
  .fdt-dialog-cdk-overlay-panel {
    margin-top: pxToRem(52) !important;
  }
}

.fdt-modal {
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  @include xs {
    height: 100%;
    max-height: 80vh;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: $spacing-16 $spacing-24;
    border-bottom: 1px solid $border-secondary;
  }

  &__title {
    @extend %subtitle-2;
  }

  &__delete-icon {
    cursor: pointer;
    color: $icon-button-secondary;
  }

  &__body {
    @extend %body-1-medium;
    padding: $spacing-24;
    overflow-y: auto;
  }
}
