@use 'shared' as *;

.fdt-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
  background: $container-primary;
  border-radius: $border-radius-12;

  &-header {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-16;
    border-bottom: 1px solid $border-secondary;
  }

  &-container {
    display: flex;
    align-items: center;
    gap: $spacing-8;
  }

  &-title {
    @extend %subtitle-2;
    color: $text-primary;
  }

  &-label {
    @extend %caption-1;
    padding: $spacing-4 $spacing-8;
    border-radius: $spacing-16;
    background: $container-secondary;
    color: $text-secondary-02;
  }

  &-body {
    padding: $spacing-24;

    @include sm {
      padding: $spacing-12;
    }
  }

  &-body--small {
    padding: $spacing-16;
  }
}
