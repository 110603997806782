@use 'shared' as *;

.tour {
  &__backdrop {
    background: transparent;
  }

  &__highlight {
    position: fixed;
    box-shadow: $overlay-dim 0 0 0 9999px; // need to be replacedd with $overlay-dim-secondary
  }

  &--active {
    background: $bg-surface-secondary;
    color: $text-primary !important;
  }
}
