@use 'shared' as *;

// Candidate for moving into the common-lib
// Especially if no more bugs will be raised about these new styles closest time
.fdt-table__row.fdt-table__body__row--clickable {
  .fdt-table__cell {
    border-bottom: unset;
    border-top: 1px solid $border-primary;
  }

  &:first-child {
    .fdt-table__cell {
      border-top: unset;
    }
  }

  &:hover,
  &:hover + .fdt-table__row {
    .fdt-table__cell {
      border-top-color: transparent;
    }
  }

  &:hover {
    .fdt-table__cell {
      $element-border-radius: $border-radius-12;

      &:first-child {
        border-bottom-left-radius: $element-border-radius;
        border-top-left-radius: $element-border-radius;
      }
      &:last-child {
        border-bottom-right-radius: $element-border-radius;
        border-top-right-radius: $element-border-radius;
      }
    }
  }
}
