// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@use 'variables' as v;
@use 'styles/globals' as *;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$FDT-ClientPortal-NextGen-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
    ),
    typography: (
      plain-family: 'Inter',
      // The font family to use for plain text, such as body text.
      brand-family: 'Twemoji Country Flags',
      // The font family to use for brand text, such as headlines.
      bold-weight: '700',
      //The font weight to use for bold text.
      medium-weight: '600',
      //The font weight to use for medium text.
      regular-weight: '500',
      //The font weight to use for regular text.
    ),
  )
);

/* You can add global styles to this file, and also import other style files */

:root {
  @include mat.all-component-themes($FDT-ClientPortal-NextGen-theme);

  // Material ovverides

  // Menu
  --mat-menu-container-color: #{v.$container-primary};
  --mat-menu-item-label-text-line-height: v.$spacing-24;
  --mat-menu-item-label-text-size: v.$spacing-16;

  // Dialog
  --mdc-dialog-container-color: #{v.$container-primary};

  // Radio
  --mat-radio-label-text-size: v.$spacing-16;

  // Checkbox
  --mdc-checkbox-selected-focus-icon-color: #{v.$icon-primary};
  --mdc-checkbox-selected-icon-color: #{v.$icon-primary};
  --mdc-checkbox-selected-hover-icon-color: #{v.$icon-primary};
  --mdc-checkbox-selected-pressed-icon-color: #{v.$icon-primary};
  --mdc-checkbox-selected-pressed-state-layer-color: #{v.$icon-primary};
  --mdc-checkbox-selected-focus-state-layer-color: #{v.$icon-primary};
  --mdc-checkbox-unselected-icon-color: #{v.$icon-secondary-01};
  --mat-checkbox-label-text-size: v.$spacing-14;

  // Mat spinner
  --mdc-circular-progress-active-indicator-color: #{v.$color-N-900};
}
